#app {
    font-family: 'Nunito Sans', sans-serif;

    @include media-breakpoint-up(sm) {
        max-width: 1280px;
        width: 1280px;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    margin: 0 auto;

    button.donate-header {
        background: #FC0D1B;
        padding: 10px 20px;
        color: white;
        font-size: 16px;
        font-weight: 900;
        font-family: 'Nunito Sans', sans-serif;
        border: none;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
            display: none;
        }
        @include media-breakpoint-up(sm) {
            display: block;
        }
    }

    button.donate {
        background: #FC0D1B;
        padding: 15px 30px;
        color: white;
        font-size: 16px;
        font-weight: 900;
        font-family: 'Nunito Sans', sans-serif;
        border: none;
        text-transform: uppercase;
    }

    .navbar {

        .navbar-toggler {
            border: none;
        }

        #navbarSupportedContent {

            a {
                color: black;
                text-decoration: none;
            }
        }

        .navbar-brand {

            @include media-breakpoint-up(sm) {
                margin-left: 90px;
            }

            @include media-breakpoint-down(sm) {
                margin-left: 20px;
            }

            img {
                height: 46px;
                width: 46px;
            }
        }

        a {
            text-decoration: none;
            color: black;
        }

        li {
            @include media-breakpoint-up(sm) {
                margin: 0 20px;
                font-size: 16px;
                font-weight: 400;
            }

            @include media-breakpoint-down(sm) {
                margin: 10px 20px;
                font-size: 16px;
                font-weight: 400;
            }


        }

        li:last-child {

            margin: 0 100px 0 0;
        }
    }

    main {
        width: 100%;

        #top-section {

            background-image: url("../../public/images/top-section2.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            @include media-breakpoint-down(sm) {
                padding-top: 100px;
            }

            @include media-breakpoint-up(sm) {
                padding-top: 200px;
            }

            h1 {
                font-weight: 700;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    font-size: 35px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 56px;
                }
            }

            h3 {
                margin-top: 10px;
                font-size: 30px;
                font-weight: 400;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    font-size: 20px !important;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 30px;
                }
            }

            button {
                background: #FC0D1B;
                border: none;
                padding: 15px 30px;
                text-transform: uppercase;
                color: white;
                margin-top: 40px;
                margin-bottom: 180px;
                font-size: 16px;
                font-weight: 900;
                font-family: 'Nunito Sans', sans-serif;
            }

            #social-logos {
                display: flex;
                width: 150px;
                justify-content: space-around;

                a {
                    color: white;
                }
            }
        }

        #about-us {
            background: white;

            @include media-breakpoint-down(sm) {
                padding: 20px;
            }

            @include media-breakpoint-up(sm) {
                padding: 100px 80px 0 80px;
            }

            img {

                @include media-breakpoint-down(sm) {
                    margin-top: 275px;

                    width: 100%;
                }

                @include media-breakpoint-up(sm) {
                    width: 539px;
                    height: 389px;
                }
            }

            #about-us-text {
                @include media-breakpoint-down(sm) {
                    position: absolute;
                    padding-right: 20px;
                }

                @include media-breakpoint-up(sm) {
                    padding-left: 52px;
                }

                h1 {
                    font-weight: 900;
                    font-family: 'Nunito Sans', sans-serif;
                    text-align: center;

                    @include media-breakpoint-down(sm) {
                        font-size: 35px;
                    }

                    @include media-breakpoint-up(sm) {
                        font-size: 40px;
                    }
                }

                h3 {
                    font-weight: 400;
                    font-family: 'Nunito Sans', sans-serif;
                    text-align: center;

                    @include media-breakpoint-down(sm) {
                        font-size: 20px;
                        p {
                            padding-right: 10px;
                            text-align: justify;
                        }
                    }

                    @include media-breakpoint-up(sm) {
                        font-size: 26px;
                    }
                }
            }
        }

        #what-we-are-doing {
            background: #F7F6FB;

            #bg-wrapper {

                @include media-breakpoint-down(sm) {
                    padding: 40px 0;
                }

                @include media-breakpoint-up(sm) {
                    padding: 60px 0;
                }

                h1 {
                    text-align: center;
                    @include media-breakpoint-down(sm) {
                        font-size: 35px;
                        margin-bottom: 95px;
                        margin-top: 30px;

                    }
                    @include media-breakpoint-up(sm) {
                        font-size: 40px;
                        margin-bottom: 20px;
                    }
                    font-weight: 900;
                    font-family: 'Nunito Sans', sans-serif;

                }

                h4 {
                    @include media-breakpoint-down(sm) {
                        font-size: 20px;
                    }

                    @include media-breakpoint-up(sm) {
                        font-size: 22px;
                    }

                    font-weight: 700;
                    font-family: 'Nunito Sans', sans-serif;
                    margin-bottom: 40px;
                }

                .boxes {
                    margin: 0 0 44px 0;

                    @include media-breakpoint-down(sm) {
                        padding: 11px 0;
                        width: 100%;
                    }

                    @include media-breakpoint-up(sm) {
                        padding: 0 100px;
                    }

                    .box {
                        @include media-breakpoint-down(sm) {
                            margin: 10px 0;
                        }

                        @include media-breakpoint-up(sm) {

                        }

                        .inside-box {
                            border-radius: 10px;
                            box-shadow: 0 4px 20px 0 rgba(80, 67, 161, 0.1);
                            background: white;

                            @include media-breakpoint-down(sm) {

                                width: 100%;
                                padding: 30px 20px;
                            }

                            @include media-breakpoint-up(sm) {
                                height: 385px;
                                width: 342px;
                                padding: 50px 11px 11px 11px;
                            }
                        }

                        img {
                            @include media-breakpoint-down(sm) {
                                height: 35px;
                                width: 35px;
                            }

                            @include media-breakpoint-up(sm) {
                                height: 80px;
                                width: 80px;
                                margin-bottom: 30px;
                            }
                        }

                        h2 {
                            font-family: 'Nunito Sans', sans-serif;
                            font-size: 30px;
                            font-weight: 700;
                            line-height: 39px;
                            margin-bottom: 20px;
                        }

                        span {
                            font-family: 'Nunito Sans', sans-serif;
                            font-size: 22px;
                            font-weight: 400;
                            line-height: 29px;
                        }
                    }
                }
            }
        }

        .white-space {
            height: 100px;
            background: white;
        }

        #together-we-can {

            @include media-breakpoint-down(sm) {
                background-image: url("../../public/images/together-section.png");
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                padding: 30px;
            }

            @include media-breakpoint-up(sm) {
                background-image: url("../../public/images/together-section.png");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                padding: 0 210px;
            }

            h1 {

                font-weight: 700;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    font-size: 25px;
                    margin-top: 70px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 40px;
                    margin-top: 140px;
                }
            }

            h3 {
                font-weight: 400;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    font-size: 22px;
                    margin-top: 15px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 22px;
                    margin-top: 30px;
                }
            }

            button {
                background: #FC0D1B;
                border: none;
                padding: 15px 30px;
                text-transform: uppercase;
                color: white;
                margin-top: 40px;

                font-size: 16px;
                font-weight: 900;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 60px;
                }

                @include media-breakpoint-up(sm) {
                    margin-bottom: 150px;
                }
            }
        }

        #thanks-section {

            background: white;

            @include media-breakpoint-down(sm) {
                padding: 90px 10px 50px;
            }

            @include media-breakpoint-up(sm) {
                padding: 100px;
            }

            .carousel-inner {
                @include media-breakpoint-down(sm) {
                    overflow: visible;
                }

                .carousel-item {
                    transition-duration: 0.2s;

                    .carousel-item-wrapper {
                        @include media-breakpoint-down(sm) {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                        }
                        @include media-breakpoint-down(sm) {
                            display: flex;
                        }

                        img {

                            @include media-breakpoint-down(sm) {
                                width: 100%;
                                top: 285px;
                            }

                            @include media-breakpoint-up(sm) {
                                margin-top: 80px;
                                height: 367px;
                                float: left;
                            }
                        }

                        #thanks-text {
                            @include media-breakpoint-down(sm) {
                                padding-top: 70px;
                                width: 100%;
                            }

                            @include media-breakpoint-up(sm) {
                                padding-left: 30px;
                                padding-top: 120px;
                                display: flex;
                                flex-direction: column;
                            }



                            h4 {
                                font-size: 20px;
                                font-weight: 900;
                                font-family: 'Nunito Sans', sans-serif;
                            }

                            h3 {
                                position: relative;
                                font-size: 26px;
                                font-weight: 400;
                                font-family: 'Nunito Sans', sans-serif;
                                margin-bottom: 20px;

                                #gap {
                                    position: absolute;
                                    @include media-breakpoint-down(sm) {
                                        height: 52px;
                                        width: 52px;
                                        top: -46px;
                                    }

                                    @include media-breakpoint-up(sm) {
                                        height: 52px;
                                        width: 52px;
                                        top: -127px;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }

        #already-done {
            background: white;

            h1 {
                font-family: 'Nunito Sans', sans-serif;

                font-weight: 900;
                line-height: 40px;

                @include media-breakpoint-down(sm) {
                    font-size: 35px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 40px;
                }
            }

            h4:first-of-type {
                margin-bottom: 10px;
            }

            ul {
                @include media-breakpoint-down(sm) {
                    padding: 0 50px;
                }

                @include media-breakpoint-up(sm) {

                }

                li {
                    font-size: 22px;
                    font-weight: 400;
                }
            }

            h4 {
                font-family: 'Nunito Sans', sans-serif;
                font-size: 22px;
                font-weight: 400;
                line-height: 29px;
                margin-bottom: 40px;

                @include media-breakpoint-down(sm) {
                    padding: 0 20px;
                }

                @include media-breakpoint-up(sm) {

                }
            }

            .boxes {
                margin-bottom: 44px;
                @include media-breakpoint-down(sm) {
                    /*padding: 11px 0;*/
                    width: 100%;
                }

                @include media-breakpoint-up(sm) {

                }

                .box {
                    padding-left: 0;
                    padding-right: 0;

                    @include media-breakpoint-down(sm) {
                        /*margin: 10px 0;*/
                    }

                    @include media-breakpoint-up(sm) {

                    }

                    .inside-box {
                        border-radius: 10px;
                        /*box-shadow: 0 4px 20px 0 rgba(80, 67, 161, 0.1);*/
                        background: white;
                        @include media-breakpoint-down(sm) {
                            padding: 0;
                        }

                        @include media-breakpoint-up(sm) {
                            padding: 0;
                        }


                        img {
                            @include media-breakpoint-down(sm) {
                                /*width: 335px;
                                height: 342px;*/
                            }

                            @include media-breakpoint-up(sm) {
                                /*width: 307px;
                                height: 314px;*/
                            }

                        }
                    }
                }
            }

            button {
                margin-bottom: 115px;
            }
        }

        #goal {
            background: white;

            h1 {
                font-family: 'Nunito Sans', sans-serif;

                font-weight: 900;
                line-height: 40px;

                @include media-breakpoint-down(sm) {
                    font-size: 35px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 40px;
                }
            }

            h4 {
                font-family: 'Nunito Sans', sans-serif;

                font-weight: 400;
                line-height: 29px;
                margin-bottom: 40px;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    font-size: 20px;
                    padding: 0 20px;
                }

                @include media-breakpoint-up(sm) {
                    width: 630px;
                    font-size: 22px;
                }
            }

            #progress-wraper {
                width: 100%;

                margin-bottom: 100px;

                @include media-breakpoint-down(sm) {
                    padding: 0 20px;
                }

                @include media-breakpoint-up(sm) {
                    padding: 0 195px;
                }

                .progress {
                    position: relative;
                    overflow: visible;

                    img {
                        position: absolute;
                        width: 45px;
                        height: 45px;
                        top: -15px;
                    }
                }

                #labels {
                    font-weight: 400;
                    font-size: 10px;
                    text-transform: uppercase;
                    line-height: 13px;
                    text-align: center;
                }

                #values {
                    font-size: 72px;
                    font-weight: 900;
                    line-height: 94px;
                    text-align: center;
                    color: #FC0D1B;
                }
            }
        }

        #payments-details {
            background: #F7F6FB;
            padding-bottom: 50px;

            i {
                cursor: pointer;
                color: blue;
            }

            h1 {
                margin-top: 40px;

                font-weight: 900;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    font-size: 35px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 40px;
                }
            }

            h3 {
                margin-top: 30px;
                font-weight: 400;
                font-family: 'Nunito Sans', sans-serif;
                margin-bottom: 70px;


                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                    padding: 0 20px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 22px;
                    width: 630px;
                }
            }

            .accordion-button, .accordion-body {
                background: #F7F6FB;
            }

            .accordion-body {

                @include media-breakpoint-down(sm) {
                    padding: 0 !important;
                }

                font-size: 22px;
                font-weight: 400;
                line-height: 29px;
                text-align: left;

                p {
                    span {

                        @include media-breakpoint-down(sm) {
                            font-size: 11px;

                        }

                        @include media-breakpoint-up(sm) {
                            font-size: 26px;
                        }
                        font-weight: 700;
                        text-align: left;
                    }
                }
            }

            .accordion-button {
                color: #FC0D1B;
                font-family: 'Nunito Sans', sans-serif;
                font-size: 26px;
                font-weight: 700;
                line-height: 26px;
            }

            #paymentsAccordion {
                width: 80%;
            }
        }

        #contacts {
            background-image: url("../../public/images/bottom-section.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            @include media-breakpoint-down(sm) {
                padding: 0 20px;
            }

            @include media-breakpoint-up(sm) {
                padding: 0 145px;
            }


            h1 {
                margin-top: 150px;

                font-weight: 700;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    font-size: 35px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 40px;
                }
            }

            h3 {
                margin-top: 20px;
                font-weight: 400;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 30px;
                }
            }

            a {
                text-decoration: none;

                button.social-button {
                    background: #FC0D1B;
                    height: 50px;
                    width: 188px;
                    color: white;
                    text-transform: uppercase;
                    border: none;

                    padding: 18px 33px;
                    font-family: 'Nunito Sans', sans-serif;
                    font-size: 16px;
                    font-weight: 800;

                }

                img.social-button {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .footer {
        max-height: 60px;
        height: 60px;
        background: black;
        display: flex;

        @include media-breakpoint-down(sm) {
            padding: 0;
            align-items: center;
            flex-direction: column;
            h5 {
                margin: 5px 0 0 0;
            }
        }

        @include media-breakpoint-up(sm) {
            padding: 0 115px;
            align-items: center;
            justify-content: space-between;
            h5 {
                margin: 0;
            }
        }

        h5 {
            color: white;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            text-transform: uppercase;

            a {
                text-decoration: none;
                color: white;
            }
        }
    }
}

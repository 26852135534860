#app {
    font-family: 'Nunito Sans', sans-serif;

    @include media-breakpoint-up(sm) {
        max-width: 1280px;
        width: 1280px;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    margin: 0 auto;

    button.donate-header {
        background: #FC0D1B;
        padding: 10px 20px;
        color: white;
        font-size: 16px;
        font-weight: 900;
        font-family: 'Nunito Sans', sans-serif;
        border: none;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
            display: none;
        }
        @include media-breakpoint-up(sm) {
            display: block;
        }
    }

    button.donate {
        background: #FC0D1B;
        padding: 15px 30px;
        color: white;
        font-size: 16px;
        font-weight: 900;
        font-family: 'Nunito Sans', sans-serif;
        border: none;
        text-transform: uppercase;
    }

    .navbar {

        .navbar-toggler {
            border: none;
        }

        #navbarSupportedContent {

            a {
                color: black;
                text-decoration: none;
            }
        }

        .navbar-brand {

            @include media-breakpoint-up(sm) {
                margin-left: 90px;
            }

            @include media-breakpoint-down(sm) {
                margin-left: 20px;
            }

            img {
                height: 46px;
                width: 46px;
            }
        }

        a {
            text-decoration: none;
            color: black;
        }

        li {
            @include media-breakpoint-up(sm) {
                margin: 0 20px;
                font-size: 16px;
                font-weight: 400;
            }

            @include media-breakpoint-down(sm) {
                margin: 10px 20px;
                font-size: 16px;
                font-weight: 400;
            }


        }

        li:last-child {

            margin: 0 100px 0 0;
        }
    }

    main {
        width: 100%;


        #our-team {
            background: white;

            @include media-breakpoint-down(sm) {
                padding-top: 100px;
            }

            @include media-breakpoint-up(sm) {
                padding: 100px 80px 0 80px;
            }

            img {

                @include media-breakpoint-down(sm) {
                    margin-top: 275px;
                    width: 100%;
                }

                @include media-breakpoint-up(sm) {
                    width: 539px;
                    height: 389px;
                }
            }

            #our-team-text {
                @include media-breakpoint-down(sm) {
                    padding-right: 20px;
                }

                @include media-breakpoint-up(sm) {
                    padding-left: 52px;
                }

                h1 {
                    font-weight: 900;
                    font-family: 'Nunito Sans', sans-serif;
                    text-align: center;

                    @include media-breakpoint-down(sm) {
                        font-size: 35px;
                    }

                    @include media-breakpoint-up(sm) {
                        font-size: 40px;
                    }
                }

                h2 {
                    font-weight: 400;
                    font-family: 'Nunito Sans', sans-serif;
                    text-align: center;
                    margin: 0 auto;
                    font-size: 30px;

                    @include media-breakpoint-down(sm) {

                        width: 335px;
                    }

                    @include media-breakpoint-up(sm) {
                        width: 717px;
                    }
                }
            }
        }

        #our-partners {
            background: white;

            @include media-breakpoint-down(sm) {
                padding: 100px 21px 60px 21px;
            }

            @include media-breakpoint-up(sm) {
                padding: 100px 80px 110px 80px;
            }

            h1 {
                font-weight: 900;
                font-family: 'Nunito Sans', sans-serif;
                text-align: center;

                @include media-breakpoint-down(sm) {
                    font-size: 35px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 40px;
                }
            }
        }

        #photos {
            #bg-wrapper {
                background: white;

                @include media-breakpoint-down(sm) {
                }

                @include media-breakpoint-up(sm) {
                    padding: 24px 0;
                }

                h1 {
                    text-align: center;
                    @include media-breakpoint-down(sm) {
                        font-size: 35px;
                        margin-bottom: 95px;
                        margin-top: 30px;

                    }
                    @include media-breakpoint-up(sm) {
                        font-size: 40px;
                        margin-bottom: 20px;
                    }
                    font-weight: 900;
                    font-family: 'Nunito Sans', sans-serif;

                }

                h4 {
                    @include media-breakpoint-down(sm) {
                        font-size: 20px;
                    }

                    @include media-breakpoint-up(sm) {
                        font-size: 22px;
                    }

                    font-weight: 700;
                    font-family: 'Nunito Sans', sans-serif;
                    margin-bottom: 40px;
                }

                .boxes {
                    margin: 0;

                    @include media-breakpoint-down(sm) {
                        padding: 11px 0;
                        width: 100%;
                    }

                    @include media-breakpoint-up(sm) {
                        padding: 0 100px;
                    }

                    .box {
                        @include media-breakpoint-down(sm) {
                            margin: 10px 0;
                        }

                        .inside-box, .inside-leader-box {
                            border-radius: 10px;
                            box-shadow: 0 4px 20px 0 rgba(80, 67, 161, 0.1);
                            background: white;

                            @include media-breakpoint-down(sm) {

                                width: 90%;
                                margin: auto;
                                padding: 30px 20px;
                            }

                            @include media-breakpoint-up(sm) {
                                width: 342px;
                                padding: 50px 25px 30px;
                            }
                        }

                        .inside-box {
                            @include media-breakpoint-down(sm) {
                                margin-top: 10px;
                            }

                            @include media-breakpoint-up(sm) {
                                margin-top: 26px;
                            }
                        }

                        img {
                            @include media-breakpoint-down(sm) {

                            }

                            @include media-breakpoint-up(sm) {
                                margin-bottom: 30px;
                            }
                        }

                        h2 {
                            font-family: 'Nunito Sans', sans-serif;
                            font-size: 30px;
                            font-weight: 700;
                            line-height: 39px;
                            margin-bottom: 20px;
                        }

                        span {
                            font-family: 'Nunito Sans', sans-serif;
                            font-size: 22px;
                            font-weight: 400;
                            line-height: 29px;
                        }


                    }
                }
            }
        }

        .scene {
            @include media-breakpoint-up(sm) {
                height: 187px;
            }
            @include media-breakpoint-down(sm) {
                height: 170px;
            }

            display: block;
            /*   border: 1px solid #CCC; */
            perspective: 1000px;

            .card {
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 100%;
                height: 100%;
                cursor: pointer;
                transform-style: preserve-3d;
                transition: transform 300ms;
                border: solid 1px #A9A9A9;
                /*box-shadow: 0 4px 20px 0 rgba(80, 67, 161, 0.1);*/
            }

            .card.is-flipped {
                transform: rotateY(-180deg);
            }

            .card__face {
                position: absolute;
                color: white;
                text-align: center;
                font-weight: bold;
                backface-visibility: hidden;
            }

            .card__face--front {
                background: white;
                color: black;

                @include media-breakpoint-up(sm) {
                    /*padding: 32px 26px;*/
                }

                @include media-breakpoint-down(sm) {
                    /*padding: 16px 12px;*/
                }

                img {
                    @include media-breakpoint-up(sm) {
                        /*width: 308px;
                        height: 123px;
                        max-height: 123px;*/
                        max-width: 80%;
                    }

                    @include media-breakpoint-down(sm) {
                        /*width: 153px;
                        height: 61px;
                        max-height: 61px;*/
                        max-width: 80%;
                    }
                }
            }

            .card__face--back {
                background: white;
                color: black;
                transform: rotateY(180deg);
                text-align: justify;
                font-style: normal;
                font-weight: 400;

                @include media-breakpoint-up(sm) {
                    padding: 16px 26px;
                    font-size: 14px;

                }
                @include media-breakpoint-down(sm) {
                    padding: 10px 5px;
                    font-size: 9px;
                }
                .text-center {
                    a {
                        text-decoration: none;
                        .learn-more {

                            @include media-breakpoint-up(sm) {
                                font-size: 14px;
                                margin-top: 22px;
                            }
                            @include media-breakpoint-down(sm) {
                                font-size: 8px;
                                margin-top: 12px;
                            }

                            color: #FC0D1B;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 17px;
                            text-transform: uppercase;

                        }
                    }
                }
            }
        }

        #payments-details {

            background: #F7F6FB;
            padding-bottom: 50px;

            i {
                cursor: pointer;
                color: blue;
            }

            h1 {
                margin-top: 40px;

                font-weight: 900;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    font-size: 35px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 40px;
                }
            }

            h3 {
                margin-top: 30px;
                font-weight: 400;
                font-family: 'Nunito Sans', sans-serif;
                margin-bottom: 70px;


                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                    padding: 0 20px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 22px;
                    width: 630px;
                }
            }

            .accordion-button, .accordion-body {
                background: #F7F6FB;
            }

            .accordion-body {

                @include media-breakpoint-down(sm) {
                    padding: 0 !important;
                }

                font-size: 22px;
                font-weight: 400;
                line-height: 29px;
                text-align: left;

                p {
                    span {

                        @include media-breakpoint-down(sm) {
                            font-size: 11px;

                        }

                        @include media-breakpoint-up(sm) {
                            font-size: 26px;
                        }
                        font-weight: 700;
                        text-align: left;
                    }
                }
            }

            .accordion-button {
                color: #FC0D1B;
                font-family: 'Nunito Sans', sans-serif;
                font-size: 26px;
                font-weight: 700;
                line-height: 26px;
            }

            #paymentsAccordion {
                width: 80%;
            }
        }

        #contacts {
            background-image: url("../../public/images/bottom-section.png");
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            @include media-breakpoint-down(sm) {
                padding: 0 20px;
            }

            @include media-breakpoint-up(sm) {
                padding: 0 145px;
            }


            h1 {
                margin-top: 150px;

                font-weight: 700;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    font-size: 35px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 40px;
                }
            }

            h3 {
                margin-top: 20px;
                font-weight: 400;
                font-family: 'Nunito Sans', sans-serif;

                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                }

                @include media-breakpoint-up(sm) {
                    font-size: 30px;
                }
            }

            a {
                text-decoration: none;

                button.social-button {
                    background: #FC0D1B;
                    height: 50px;
                    width: 188px;
                    color: white;
                    text-transform: uppercase;
                    border: none;

                    padding: 18px 33px;
                    font-family: 'Nunito Sans', sans-serif;
                    font-size: 16px;
                    font-weight: 800;

                }

                img.social-button {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .footer {
        max-height: 60px;
        height: 60px;
        background: black;
        display: flex;

        @include media-breakpoint-down(sm) {
            padding: 0;
            align-items: center;
            flex-direction: column;
            h5 {
                margin: 5px 0 0 0;
            }
        }

        @include media-breakpoint-up(sm) {
            padding: 0 115px;
            align-items: center;
            justify-content: space-between;
            h5 {
                margin: 0;
            }
        }

        h5 {
            color: white;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            text-transform: uppercase;

            a {
                text-decoration: none;
                color: white;
            }
        }
    }
}


// Fonts
//@import 'fonts';
//@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'mainpage';
@import 'team';
